import _ from 'lodash'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  BannerCTA,
  ClientsCard,
  ClientsCarousel,
  Seo,
  StickyMenu,
  Spinner,
  Tagline,
  TitleTextBlock,
} from '../../components'
import './home-styles.scss'

import HeroHomePage from './components/hero'
import IndustriesHome from './components/industries'
import NumbersWhy from './components/numbers-why'
import WhatWeOffer from './components/what-we-offer'
import WhyPartner from './components/why-partner'

const HomeView = ({ data, loading }) => {
  const { t, i18n } = useTranslation()

  if (loading) {
    return <Spinner loading={loading} />
  }

  // data.map((layout) => layout.acf_fc_layout)
  const menu = [
    {
      name: t('tagline.our-mission'),
      id: 'our-mission',
    },
    {
      name: t('tagline.our-strengths'),
      id: 'our-strengths',
    },
    {
      name: t('tagline.our-services'),
      id: 'our-services',
    },
    {
      name: t('tagline.industries'),
      id: 'industries',
    },
    // {
    //   name: t('tagline.our-work'),
    //   id: 'our-works',
    // },
    {
      name: t('tagline.our-clients'),
      id: 'our-clients',
    },
  ]

  return (
    <React.Fragment>
      <div className="home__container">
        <Seo title="Season Group: Home" />
        <StickyMenu
          menu={menu}
          slug={null}
        />

        {/* Hero component */}
        <HeroHomePage data={_.find(data, { acf_fc_layout: 'hero_two_images' })} />

        {/* block; tagline, title, text */}

        <div className="home__layout">
          {_.map(data, (layouts, idx) => {
            let layout
            switch (layouts.acf_fc_layout) {
              // Our mission
              case 'two_column_text':
                layout = <div key={idx} className="home__title" id="our-mission">
                  <TitleTextBlock
                    textButton={t('home.buttons.mission')}
                    link={`${i18n.language === 'en' ? '/' : `/${i18n.language}/`}about-us/`}
                    text={t('tagline.our-mission')}
                    descr={layouts.description}>
                    <Trans i18nKey='home.our-mission-title'>
                      Improve world&apos;s living standard through <span className='title-color'>{{ innovative: i18n.language !== 'en' ? '创新' : 'innovative' }}</span> product development.
                    </Trans>
                  </TitleTextBlock></div>
                break

              // numbers why
              case 'numbers':
                layout = <div className='home__numbers' key={idx}>
                  {layouts.list.map((number, i) => <NumbersWhy key={i} number={number.number} descr={number.description} />)}
                </div>
                break

              // why partner with us
              case 'image_numbered_blocks':
                layout = <div className='home__why-partner' key={idx} id="our-strengths">
                  <WhyPartner data={layouts} />
                </div>
                break

              // our services; multiple components switching layout right/left
              case 'info_blocks_list':
                layout = <div className='home__whatWeOfferContainer' key={idx} id="our-services">
                  <div className='home__whatWeOfferDots'></div>
                  <WhatWeOffer data={layouts} />
                </div>
                break

              // industries list; do we take it in like this or via industries page?
              case 'industries_list':
                layout = <div className='home__industriesContainer' key={idx} id="industries">
                  <div className='home__whatWeOfferContainer'><div className='home__industriesDots'></div>
                    <Tagline text={t('tagline.industries')} />
                    <p className="largeTitle">
                      <Trans i18nKey='home.who-we-serve'>
                        Who we <span className='title-color'>{{ serve: i18n.language !== 'en' ? '服務' : 'serve' }}</span>
                      </Trans>
                    </p>
                    <div className="home__industriesSubcontainer">
                      {layouts.list.map((industries, i) => (
                        <IndustriesHome key={i} title={industries.title} slug={industries.slug}/>
                      ))}
                    </div>
                  </div>
                </div>
                break

                // // our work
                // case 'case_studies_list':
                //   layout = <div key={idx} className="home__carousel" id='our-works'>
                //     <div className="carousel__tagline">
                //       <Tagline text={t('tagline.our-work')} />
                //     </div>
                //     <Carousel data={layouts.list}>
                //       {layouts.list.map((work, i) => <div key={i}>
                //         <ShowcaseCard
                //           imageUrl={work.image.url}
                //           imageAlt={work.image.alt}
                //           title={work.title}
                //           descr={work.content}
                //           link={`/case-studies/${work.slug}`}
                //         />
                //       </div>)}
                //     </Carousel>
                //   </div>
                //   break

              // clients
              case 'clients':
                layout = <div key={idx} className="home__clients" id="our-clients">
                  <ClientsCarousel hasBar={true} data={layouts.list}>
                    {layouts.list.map((work, i) => <div key={i}>
                      <ClientsCard
                        imageUrl={work.image.url}
                        imageAlt={work.image.alt}
                        title={work.title}
                        descr={work.copy}
                        companyName={work.position_company}
                      />
                    </div>)}
                  </ClientsCarousel>
                </div>
                break

              default:
                break
            }
            return layout
          })}
        </div>

      </div>
      <BannerCTA />

    </React.Fragment>
  )
}

export default HomeView
