import _ from 'lodash'
import React, { useState } from 'react'
import { Link } from 'gatsby'
import ButtonCircle from '../../../../components/button-circle'
import './industries-styles.scss'
import { useTranslation } from 'react-i18next'

const IndustriesHome = ({ title, slug }) => {
	const { i18n } = useTranslation();
  const [onHover, setOnHover] = useState()

  const handleMouseOver = () => {
    setOnHover(true)
  }

  const handleMouseOut = () => {
    setOnHover(false)
  }

  return (
    <div className="industries__home__container">
      <div className="industries__home__subcontainer" onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
        <Link to={`${i18n.language === 'en' ? '/' : `/${i18n.language}/`}industries/${slug}`}><h3 dangerouslySetInnerHTML={{ __html: title }} /></Link>
        {onHover ? <ButtonCircle link={`${i18n.language === 'en' ? '/' : `/${i18n.language}/`}industries/${slug}`} transparent /> : <ButtonCircle link={`${i18n.language === 'en' ? '/' : `/${i18n.language}/`}industries/${slug}`} />}
      </div>
    </div>
  )
}

export default IndustriesHome
