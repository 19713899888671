import _ from 'lodash'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import './whypartner-styles.scss'
import { Tagline, Icon } from '../../../../components'

const WhyPartner = ({ data }) => {
  const { list } = data
  const { t, i18n } = useTranslation()

  return (
    <div className="whypartner__container">
      <Tagline text={t('tagline.our-strengths')} />
      <p className="largeTitle">
        <Trans i18nKey='home.partner-with-us'>
          Why <span className='title-color'>{{ partner: i18n.language !== 'en' ? '夥伴' : 'partner' }}</span> with us.
        </Trans>
      </p>
      <div className="whypartner__subcontainer">
        <div className='whypartner__main-image'>
          <div style={{
            backgroundImage: `url(${_.get(data, 'image.url')})`,
          }}>

          </div>
        </div>
        <div className="whypartner__reasons__container">

          {_.map(list, (l, idx) => (
            <div className='whypartner__reasons' key={idx}>
              <div className='whypartner__icon'>
                <Icon name={`icon${idx + 1}`}/>
              </div>
              <div className='whypartner__title-text-container'>
                <h3>{l.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: l.copy }} />
              </div>
            </div>
          ))}

        </div>
      </div>
    </div>
  )
}

export default WhyPartner
