import _ from 'lodash'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import './what-we-offer-styles.scss'
import Tagline from '../../../../components/tagline'
import ServicesCard from './services-card'

const WhatWeOffer = ({ data }) => {
  const { items } = data
  const { t, i18n } = useTranslation()

  return (
    <div className="whatWeOffer__container">
      <Tagline text={t('tagline.our-services')} />
      <p className="largeTitle">
        <Trans i18nKey='home.what-we-offer'>
          What we <span className='title-color'>{{ offer: i18n.language !== 'en' ? '提供' : 'offer' }}</span>
        </Trans>
      </p>

      {items && items.map((industry, idx) => (
        idx % 2 === 0 ? <ServicesCard
          key={idx}
          imageUrl={industry.image?.url}
          imageAlt={industry.image?.alt}
          title={industry.title}
          slug={industry.slug}
          text={industry.copy}
          imageSecondUrl={industry.image_second?.url}
          imageSecondAlt={industry.image_second?.alt}
        /> : <ServicesCard
          key={idx}
          imageUrl={industry.image?.url}
          imageAlt={industry.image?.alt}
          title={industry.title}
          slug={industry.slug}
          text={industry.copy}
          imageSecondUrl={industry.image_second?.url}
          imageSecondAlt={industry.image_second?.alt}
          oppositeCard />
      ))
      }

    </div>
  )
}

export default WhatWeOffer
