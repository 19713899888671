import _ from 'lodash'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import './hero-styles.scss'

const HeroHomePage = ({
  data,
}) => {
  const { t, i18n } = useTranslation()

  return (
    <div className="hero__container">
      <div className="hero__subcontainer">
        <div
          style={{
            backgroundImage: `url(${_.get(data, 'image.url')})`,
          }}
          className='hero__main-image'>
        </div>
        <div className="hero__right">
          <h1>
            <Trans i18nKey='home.heroTitle'>
                  Global <span className='hero__title-color'>{{ vertical: i18n.language !== 'en' ? '纵向' : 'Vertical' }}</span> Together
            </Trans>
          </h1>
          <div
            style={{
              backgroundImage: `url(${_.get(data, 'image_second.url')})`,
            }}
            className="hero__secondImage">
          </div>
        </div>
        <div className="hero__scroll-container">
          <div className='scroll'>
            <div className="line"></div>
            <p>{t("ui.scroll")}</p>
          </div>
        </div>
      </div>
      <div className='hero__button-image'></div>
      <div className="hero__dots"></div>
    </div>
  )
}

export default HeroHomePage
